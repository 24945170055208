import React from 'react'
import { Redirect } from "react-router-dom";

class Logout extends React.Component {
	componentDidMount() {
		window.localStorage.removeItem('jwt')
	}

	render() {
		return <Redirect to='/'/>
	}
}

export default Logout;