import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Loader from './Loader'
import Layout from './Layout'
import Button from './Button'
import Container from './Container'
import styles from '../styles/Share.module.scss'

class Share extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			key: null,
			isPublic: false,
			isLoading: true,
		}

		this.getPermalink = this.getPermalink.bind(this)
	}

	componentDidMount() {
		axios.get(process.env.REACT_APP_API_ENDPOINT + '/user-key').then(res => {
			this.setState({
				key: res.data.key,
				isPublic: res.data.isPublic,
				isLoading: false,
			})
		});
	}

	getPermalink() {
		let url = 'mirra.live/' + this.state.key
		if(this.state.isPublic) {
			return (
				<Link to={this.state.key}>
					<Button label={url} />
				</Link>
			)
		} else {
			return <Button label={url} soft/>
		}
	}

	render() {
		if (this.state.isLoading) return <Loader />

		return (
			<Layout>
				<div className={styles.permalink}>
					{this.getPermalink()}
				</div>
				<Container>
					<section>
						<h1>Your permalink</h1>
						<p>
							This is your unique permalink, send it to your friends or post it 
							on your socials to let anyone see what you are currently listening
						</p>
					</section>
				</Container>
			</Layout>
		)
	}
}

export default Share;