import React  from 'react'
import Nav from './Nav'
import styles from '../styles/Layout.module.scss'

function Layout(props) {
	return (
		<div className={styles.layout}>
			{ props.noNav ? null : <Nav />}
			<div>
				{props.children}
			</div>
		</div>
	)
}

export default Layout;