import React  from 'react'
import styles from '../styles/Container.module.scss'

function Container(props) {
	return (
		<div className={styles.content}>
			{props.children}
		</div>
	)
}

export default Container;