import React from 'react'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import logo from '../images/logo-short.svg'
import menu from '../images/menu.svg'
import close from '../images/close.svg'
import styles from '../styles/Nav.module.scss'

class Nav extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isMenuOpen: false,
		}

		this.toogleMenu = this.toogleMenu.bind(this)
	}

	toogleMenu() {
		this.setState({isMenuOpen: !this.state.isMenuOpen})
	}

	render () {
		return (
			<header className={styles.nav}>
				<Link to=''>
					<img src={logo} alt='logo' />
				</Link>
				<img 
					src={this.state.isMenuOpen ? close : menu}
					alt='menu'
					onClick={this.toogleMenu}
				/>
				<Menu show={this.state.isMenuOpen} />
			</header>
		)
	}
}

export default Nav;