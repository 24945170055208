import React from 'react';
import { Link } from "react-router-dom"
import axios from 'axios'
import Button from './Button'
import Layout from './Layout';
import Loader from './Loader';
import styles from '../styles/Setttings.module.scss'

class Settings extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isPublic: false,
			isLoading: true,
		}

		this.share = this.share.bind(this)
		this.unshare = this.unshare.bind(this)
		this.getSessionToogle = this.getSessionToogle.bind(this)
	}

	componentDidMount() {
		axios.get(process.env.REACT_APP_API_ENDPOINT + '/user-key')
			.then(res => this.setState({
				isPublic: res.data.isPublic,
				isLoading: false,
			}));
	}

	share() {
		axios.get(process.env.REACT_APP_API_ENDPOINT + '/share')
			.then(() => this.setState({isPublic: true}));
	}

	unshare() {
		axios.get(process.env.REACT_APP_API_ENDPOINT + '/unshare')
			.then(() => this.setState({isPublic: false}));
	}

	getSessionToogle() {
		if(this.state.isPublic) {
			return (
				<section>
					<h1>Private Session</h1>
					<p>
						You can have a private listening session in any time. 
						Other users will not be able to see what you are listening
					</p>
					<Button onClick={this.unshare} label="Make it private" />
				</section>
			)
		} else {
			return (
				<section>
					<h1>Private Session</h1>
					<p>
						You can have a private listening session in any time. 
						Other users will not be able to see what you are listening
					</p>
					<Button onClick={this.share} label="Go live" />
				</section>
			)
		}
	}

	render() {
		if(this.state.isLoading) return <Loader />

		return (
			<Layout>
				<div className={styles.settings}>
					{this.getSessionToogle()}
					<section>
						<h1>Delete Account</h1>
						<p>You will be able to login back again in any time, but your unique permalink may vary.</p>
						<Link to='/delete'>
							<Button label='Delete account'/>
						</Link>
					</section>
				</div>
			</Layout>
		)
	}
}

export default Settings