import React from 'react';
import Logo from './Logo'
import Loader from './Loader'
import Button from './Button'
import Container from './Container'
import Layout from './Layout';
import styles from '../styles/Login.module.scss'


class Home extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isLoding: false,
		}

		this.setLoading = this.setLoading.bind(this)
	}

	setLoading() {
		this.setState({isLoding: true})
	}

	render() {
		if(this.state.isLoding) return <Loader />

		let authorizeUrl = process.env.REACT_APP_API_ENDPOINT + '/authorize'

		return (
			<Layout noNav>
				<div className={styles.header}>
					<Logo />
					<h1>Share your own musical taste</h1>
					<a href={authorizeUrl} onClick={this.setLoading}>
						<Button label="Login with Spotify" />
					</a>
				</div>
				<Container>
					<section>
						<h1>What’s Mirra</h1>
						<p>
							Mirra let’s you share what you are listening on Spotify in real time.
							You can send your unique permalink to your friends or post it on your 
							socials to let anyone see what you are currently listening
						</p>
					</section>
					<section>
						<h1>Private session</h1>
						<p>
							With the “private session” mode enabled no one will be able to see what 
							you are listening. You can turn it on and off whenever you want
						</p>
					</section>
					<section>
						<h1>Spotify Premium</h1>
						<p>
							Mirra is powered by Spotify Premium and its awesome API
						</p>
					</section>
				</Container>
			</Layout>
		)
	}
}

export default Home;