import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/Menu.module.scss'

function Menu(props) {
	if(!props.show) return null

	return (
		<div className={styles.wrapper}>
			<ul>
				<li>
					<Link to='/settings'>
						Settings
					</Link>
				</li>
				<li>
					<Link to='/logout'>
						Logout
					</Link>
				</li>
			</ul>
		</div>
	)
	
}

export default Menu;